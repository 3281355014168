<template>
  <div>
    <el-card class="mt ml mr">
      <div class="gurrent">
        <p class="p1 fl">
          <span>{{ $t("account.avatar") }}:</span>
        </p>
        <!-- <img :src="avatarUrl" class="img fl ml" @click="uploadImg" />
        <input
          type="file"
          ref="upload"
          style="display: none"
          accept="image/gif,image/jpeg,image/png,image/jpg"
          @change="freshImg"
        /> -->
        <img
          src="@/assets/default_brand_logo.png"
          v-if="avatarUrl === ''"
          style="width: 100px; height: 100px"
          class="fl ml"
        />
        <el-image
          v-else
          :src="avatarUrl + '?x-oss-process=style/w128'"
          style="width: 100px; height: 100px"
          class="fl ml"
        ></el-image>
        <MaterialSelector
          :count="1"
          type="image"
          style="margin-left: 50px"
          @receiver="handleSelectionResult($event, 1)"
        >
          <template v-slot:custom-open-button>
            <p class="p2 fl">
              {{ $t("store.avatar") }}<br />
              <span style="color: #000000; font-size: 13px">{{
                $t("store.file")
              }}</span>
            </p>
          </template>
        </MaterialSelector>
        <div class="clear"></div>
      </div>

      <el-form :model="data" :rules="rules" ref="data" label-width="10%">
        <el-form-item
          prop="name"
          class="name mt"
          :label="this.$t('account.name')"
        >
          <el-input
            v-model="data.nickName"
            placeholder=""
            class="input1"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="mobile"
          class="name mt"
          :label="this.$t('account.mobile')"
        >
          <el-input
            v-model="data.mobile"
            placeholder=""
            class="input1"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="email"
          class="name mt"
          :label="this.$t('account.email')"
        >
          <el-input
            v-model="data.email"
            placeholder="1226+3233@255.com"
            class="input3"
            disabled
          ></el-input>
        </el-form-item>

        <el-button class="button" @click="updateData">{{
          $t("account.preservation")
        }}</el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { editUser, getUserDetail } from "../../utils/api";
import MaterialSelector from "@/components/MaterialSelector";
export default {
  components: {
    MaterialSelector,
  },
  data() {
    var email = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t("login.emailreg")));
      }
    };
    return {
      data: {
        img: "",
        name: "",
        mobile: "",
        email: "",
      },
      avatar: "",
      avatarUrl: "",
      storeId: 0,
      rules: {
        name: [
          {
            required: false,
            message: this.$t("store.names"),
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: this.$t("store.mobileRequired"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("login.emails"),
            trigger: "blur",
          },
          { validator: email, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      let userInfo = JSON.parse(localStorage.getItem("vendor_user"));
      this.storeId = userInfo.userId;
      getUserDetail({
        id: userInfo.userId,
      }).then((res) => {
        // console.log("0000000", res);
        this.data = res.data;
        this.avatarUrl = res.data.img;
        localStorage.setItem("img", res.data.img);
      });
    },
    handleSelectionResult(result, type) {
      // console.log("result, type:", result, type);
      if (type === 1) {
        // 主图
        this.avatarUrl = result.filename;
        // this.gallery = [...gallery];
        // console.log("this.avatarUrl:", this.avatarUrl);
      }
    },
    updateData() {
      console.log("aaaaaaa", this.storeId);
      editUser({
        userId: this.storeId,
        img: this.avatarUrl,
        nickName: this.data.nickName,
        mobile: this.data.mobile,
        email: this.data.email,
      }).then((res) => {
        console.log("0000000", res);
        if (res.errcode == 0) {
          this.$message({
            message: this.$t("common.updateSuccess"),
            type: "success",
          });
          // 刷新页面
          this.$router.go(0);
        }
      });
    },
    // uploadImg() {
    //   this.$refs["upload"].click();
    // },
    // freshImg(e) {
    //   let _this = this;
    //   _this.imgObj = e.target.files["0"]; //得到上传的第一个文件
    //   let fr = new FileReader(); //异步读取存储在用户计算机上的文件（或原始数据缓冲区）的内容
    //   fr.onload = function () {
    //     _this.avatarUrl = fr.result;
    //     _this.avatar = _this.avatarUrl.replace(/^data:image\/\w+;base64,/, "");
    //   };
    //   fr.readAsDataURL(_this.imgObj); //将读取到的文件编码成Data URL
    // },
  },
};
</script>

<style scoped>
.gurrent {
  margin: 20px 0 0 20px;
}
.gurrent .p1 {
  font-size: 16px;
  font-weight: 700;
}
.img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-left: 35px;
}
.gurrent .p2 {
  margin: 30px 0 0 50px;
  color: #ff0a0a;
  cursor: pointer;
}
.name .el-input__inner {
  width: 30%;
}
/* .name .input1 {
  width: 400px;
}
.name .input2 {
  width: 330px;
}
.name .input3 {
  width: 400px;
}
.name .input4 {
  width: 372px;
}
.name span {
  margin-right: 10px;
  font-size: 13px;
  font-weight: 700;
} */
.button {
  width: 218px;
  height: 51px;
  background: #ff0a0a;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  margin: 30px 0 20px 20px;
}
</style>